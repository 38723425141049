<div class="layout-container layout-slim-plus layout-light" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <app-sidebar></app-sidebar>
  <div class="layout-content-wrapper" [ngClass]="{ 'layout-content-wrapper--mobile-landscape': isSmall && !isPortrait }">
    <div id="layout-content" class="layout-content p-3">
      <div class="layout-content-inner">
        <main class="main-arrea__wrapper" [@routeAnimations]="getRouteAnimationData()">
          <div class="main-area__helper" [style.width]="'calc(100% - 3rem)'"></div>
          <router-outlet></router-outlet>
          <div class="main-arrea__spinner" *ngIf="isLoading">
            <p-progressSpinner></p-progressSpinner>
          </div>
        </main>
      </div>
    </div>
  </div>
  <app-config></app-config>
  <app-filters></app-filters>
</div>
