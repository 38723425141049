<ion-app>
  <router-outlet></router-outlet>
</ion-app>

<p-toast styleClass="toast__wrapper">
  <ng-template let-message pTemplate="message">
    <div class="flex align-items-start gap-3 w-full">
      <i class="pi" [ngClass]="message.icon" [style.fontSize.px]="24"></i>
      <span class="text-xl">
        <ng-container *ngFor="let text of message.summary.split('#')">
          <span [innerHtml]="toastMessageVerification(text) | translate : message.data"></span>
        </ng-container>
      </span>
    </div>
  </ng-template>
</p-toast>
