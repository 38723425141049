import { createAction, props } from '@ngrx/store';
import { Client } from 'src/app/models/client';
import { Collaborator } from 'src/app/models/collaborator';
import { P360DropdownItem } from 'src/app/models/common';
import { DateTypeValue } from 'src/app/models/date-type';
import { Dispatcher } from 'src/app/models/dispatcher';
import { FormatTypeValue } from 'src/app/models/format-type';
import { Gen0039 } from 'src/app/models/general-information/gen-0039.specialty';
import { Gen0041 } from 'src/app/models/general-information/gen-0041.social-media';
import { GlobalCatalog } from 'src/app/models/global-catalog';
import { Line } from 'src/app/models/line';
import { ProductItem } from 'src/app/models/product-item';
import { Segment } from 'src/app/models/segment';

const appStoreFiltersLabels = {
  /* eslint-disable @typescript-eslint/naming-convention */
  SET_DATE_SELECTED: 'SET_DATE_SELECTED',
  SET_FORMAT_TYPE: 'SET_FORMAT_TYPE',
  SET_DATE_TYPE: 'SET_DATE_TYPE',

  SET_COLLABORATOR_LIST: 'SET_COLLABORATOR_LIST',
  SET_COLLABORATOR_OPTION: 'SET_COLLABORATOR_OPTION',
  CLEAR_COLLABORATOR_FILTER: 'CLEAR_COLLABORATOR_FILTER',

  SET_CLIENT_LIST: 'SET_CLIENT_LIST',
  SET_CLIENT_OPTION: 'SET_CLIENT_OPTION',
  CLEAR_CLIENT_FILTER: 'CLEAR_CLIENT_FILTER',

  SET_CLIENT_FOR_FILTERS_LIST: 'SET_CLIENT_FOR_FILTERS_LIST',
  SET_CLIENT_FOR_FILTERS_OPTION: 'SET_CLIENT_FOR_FILTERS_OPTION',
  CLEAR_CLIENT_FOR_FILTERS_FILTER: 'CLEAR_CLIENT_FOR_FILTERS_FILTER',

  SET_LINE_LIST: 'SET_LINE_LIST',
  SET_LINE_OPTION: 'SET_LINE_OPTION',
  CLEAR_LINE_FILTER: 'CLEAR_LINE_FILTER',

  SET_SEGMENT_LIST: 'SET_SEGMENT_LIST',
  SET_SEGMENT_OPTION: 'SET_SEGMENT_OPTION',
  CLEAR_SEGMENT_FILTER: 'CLEAR_SEGMENT_FILTER',

  SET_REGION_OPTION: 'SET_REGION_OPTION',
  CLEAR_REGION_FILTER: 'CLEAR_REGION_FILTER',

  SET_ANALYTICS_CUBES_SELLIN_SELECTED: 'SET_ANALYTICS_CUBES_SELLIN_SELECTED',
  CLEAR_ANALYTICS_CUBES_SELLIN_FILTER: 'CLEAR_ANALYTICS_CUBES_SELLIN_FILTER',

  SET_ANALYTICS_CUBES_AUDIT_SELECTED: 'SET_ANALYTICS_CUBES_AUDIT_SELECTED',
  CLEAR_ANALYTICS_CUBES_AUDIT_FILTER: 'CLEAR_ANALYTICS_CUBES_AUDIT_FILTER',

  SET_ANALYTICS_CUBES_AUDIT_MEASURE_OPTION:
    'SET_ANALYTICS_CUBES_AUDIT_MEASURE_OPTION',
  CLEAR_ANALYTICS_CUBES_AUDIT_MEASURE_FILTER:
    'CLEAR_ANALYTICS_CUBES_AUDIT_MEASURE_FILTER',

  SET_ANALYTICS_CUBES_KPI_OPTION: 'SET_ANALYTICS_CUBES_KPI_OPTION',
  CLEAR_ANALYTICS_CUBES_KPI_FILTER: 'CLEAR_ANALYTICS_CUBES_KPI_FILTER',

  SET_DRILLDOWN_PERSPECTIVE_OPTION: 'SET_DRILLDOWN_PERSPECTIVE_OPTION',
  CLEAR_DRILLDOWN_PERSPECTIVE_FILTER: 'CLEAR_DRILLDOWN_PERSPECTIVE_FILTER',

  SET_KPIS_MEASURE_OPTION: 'SET_KPIS_MEASURE_OPTION',
  CLEAR_KPIS_MEASURE_FILTER: 'CLEAR_KPIS_MEASURE_FILTER',

  SET_KPIS_HEATMAP_MEASURE_TYPE_OPTION: 'SET_KPIS_HEATMAP_MEASURE_TYPE_OPTION',
  CLEAR_KPIS_HEATMAP_MEASURE_TYPE_FILTER:
    'CLEAR_KPIS_HEATMAP_MEASURE_TYPE_FILTER',

  SET_RX_MEASURE_OPTION: 'SET_RX_MEASURE_OPTION',
  CLEAR_RX_MEASURE_FILTER: 'CLEAR_RX_MEASURE_FILTER',

  SET_RX_MEASURE_TYPE_OPTION: 'SET_RX_MEASURE_TYPE_OPTION',
  CLEAR_RX_MEASURE_TYPE_FILTER: 'CLEAR_RX_MEASURE_TYPE_FILTER',
  /* eslint-enable @typescript-eslint/naming-convention */
};

export const appStoreFiltersActions = {
  setDateSelectedAction: createAction(
    appStoreFiltersLabels.SET_DATE_SELECTED,
    props<{ dateSelected: Date }>()
  ),

  setFormatTypeAction: createAction(
    appStoreFiltersLabels.SET_FORMAT_TYPE,
    props<{ formatTypeSelected: FormatTypeValue }>()
  ),

  setDateTypeAction: createAction(
    appStoreFiltersLabels.SET_DATE_TYPE,
    props<{ dateTypeSelected: DateTypeValue }>()
  ),

  setGlobalCatalogListAction: createAction(
    'SET_GLOBAL_CATALOG_LIST',
    props<{ globalCatalogList: GlobalCatalog[] }>()
  ),

  setGlobalProductItemListAction: createAction(
    'SET_GLOBAL_PRODUCT_ITEM_LIST',
    props<{ productItemList: ProductItem[] }>()
  ),

  setGlobalDispatcherListAction: createAction(
    'SET_GLOBAL_DISPATCHER_LIST',
    props<{ dispatcherList: Dispatcher[] }>()
  ),

  setCollaboratorListAction: createAction(
    appStoreFiltersLabels.SET_COLLABORATOR_LIST,
    props<{ collaboratorFilterList: any }>()
  ),

  setCollaboratorOptionAction: createAction(
    appStoreFiltersLabels.SET_COLLABORATOR_OPTION,
    props<{ collaboratorFilterOption: Collaborator }>()
  ),

  clearCollaboratorFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_COLLABORATOR_FILTER
  ),

  setClientListAction: createAction(
    appStoreFiltersLabels.SET_CLIENT_LIST,
    props<{ clientFilterList: P360DropdownItem<Client>[] }>()
  ),

  setClientOptionAction: createAction(
    appStoreFiltersLabels.SET_CLIENT_OPTION,
    props<{ clientFilterOption: Client }>()
  ),

  clearClientFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_CLIENT_FILTER
  ),

  setClientForFiltersListAction: createAction(
    appStoreFiltersLabels.SET_CLIENT_FOR_FILTERS_LIST,
    props<{ clientForFiltersFilterList: P360DropdownItem<Client>[] }>()
  ),

  setClientForFiltersOptionAction: createAction(
    appStoreFiltersLabels.SET_CLIENT_FOR_FILTERS_OPTION,
    props<{ clientForFiltersFilterOption: Client }>()
  ),

  clearClientForFiltersFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_CLIENT_FOR_FILTERS_FILTER
  ),

  setLineListAction: createAction(
    appStoreFiltersLabels.SET_LINE_LIST,
    props<{ lineFilterList: P360DropdownItem<Line>[] }>()
  ),

  setLineOptionAction: createAction(
    appStoreFiltersLabels.SET_LINE_OPTION,
    props<{ lineFilterOption: Line }>()
  ),

  clearLineFilterAction: createAction(appStoreFiltersLabels.CLEAR_LINE_FILTER),

  setSegmentListAction: createAction(
    appStoreFiltersLabels.SET_SEGMENT_LIST,
    props<{ segmentFilterList: P360DropdownItem<Segment>[] }>()
  ),

  setSegmentOptionAction: createAction(
    appStoreFiltersLabels.SET_SEGMENT_OPTION,
    props<{ segmentFilterOption: Segment }>()
  ),

  setSpecialtiesAction: createAction(
    '[FILTERS_SET_SPECIALTIES]',
    props<{ specialties: Gen0039[] }>()
  ),

  setSpecialtyOptionAction: createAction(
    '[FILTERS_SET_SPECIALTY_OPTION]',
    props<{ specialtyCode: string }>()
  ),

  setSocialMediaListAction: createAction(
    '[FILTERS_SET_SOCIAL_MEDIAS]',
    props<{ socialMediaList: Gen0041[] }>()
  ),

  clearSegmentFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_SEGMENT_FILTER
  ),

  setRegionFilterOptionAction: createAction(
    appStoreFiltersLabels.SET_REGION_OPTION,
    props<{ regionFilterOption: P360DropdownItem<string> }>()
  ),

  clearRegionFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_REGION_FILTER
  ),

  setAllCollaboratorsListAction: createAction(
    'SET_ALL_COLLABORATORS_LIST',
    props<{ allCollabortorsList: Collaborator[] }>()
  ),

  setAnalyticsCubesSellinSelectedAction: createAction(
    'SET_ANALYTICS_CUBES_SELLIN_SELECTED',
    props<{ name: string; option: any }>()
  ),

  clearAnalyticsCubesSellinFilterAction: createAction(
    'CLEAR_ANALYTICS_CUBES_SELLIN_FILTER',
    props<{ name: string }>()
  ),

  setAnalyticsCubesAuditSelectedAction: createAction(
    'SET_ANALYTICS_CUBES_SELLIN_SELECTED',
    props<{ name: string; option: any }>()
  ),

  clearAnalyticsCubesAuditFilterAction: createAction(
    'CLEAR_ANALYTICS_CUBES_SELLIN_FILTER',
    props<{ name: string }>()
  ),

  setAnalyticsCubesAuditMeasureOptionAction: createAction(
    appStoreFiltersLabels.SET_ANALYTICS_CUBES_AUDIT_MEASURE_OPTION,
    props<{ auditMeasureOption: P360DropdownItem<string> }>()
  ),

  clearAnalyticsCubesAuditMeasureFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_ANALYTICS_CUBES_AUDIT_MEASURE_FILTER
  ),

  setAnalyticsCubesKpiOptionAction: createAction(
    appStoreFiltersLabels.SET_ANALYTICS_CUBES_KPI_OPTION,
    props<{ kpiType?: string; kpiLabel?: string; kpiValue?: string }>()
  ),

  clearAnalyticsCubesKpiFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_ANALYTICS_CUBES_KPI_FILTER
  ),

  setDrilldownPerspectiveOptionAction: createAction(
    appStoreFiltersLabels.SET_DRILLDOWN_PERSPECTIVE_OPTION,
    props<{ drilldownPerspectiveOption: P360DropdownItem<string> }>()
  ),

  clearDrilldownPerspectiveFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_DRILLDOWN_PERSPECTIVE_FILTER
  ),

  setKpisMeasureOptionAction: createAction(
    appStoreFiltersLabels.SET_KPIS_MEASURE_OPTION,
    props<{ heatmapMeasureOption: P360DropdownItem<string> }>()
  ),

  clearKpisMeasureFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_KPIS_MEASURE_FILTER
  ),

  setKpisHeatmapMeasureTypeOptionAction: createAction(
    appStoreFiltersLabels.SET_KPIS_HEATMAP_MEASURE_TYPE_OPTION,
    props<{ kpiType?: string; kpiLabel?: string; kpiValue?: string }>()
  ),

  clearKpisHeatmapMeasureTypeFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_KPIS_HEATMAP_MEASURE_TYPE_FILTER
  ),

  setRxMeasureOptionAction: createAction(
    appStoreFiltersLabels.SET_RX_MEASURE_OPTION,
    props<{ rxMeasureOption: P360DropdownItem<string> }>()
  ),

  clearRxMeasureFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_RX_MEASURE_FILTER
  ),

  setRxMeasureTypeOptionAction: createAction(
    appStoreFiltersLabels.SET_RX_MEASURE_TYPE_OPTION,
    props<{ rxType?: string; rxLabel?: string; rxValue?: string }>()
  ),

  clearRxMeasureTypeFilterAction: createAction(
    appStoreFiltersLabels.CLEAR_RX_MEASURE_TYPE_FILTER
  ),
};
