import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { VonRestService } from '@von-development-studio/angular-rest-service';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, share, take, tap } from 'rxjs/operators';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { AppStorage } from 'src/app/core/utils/app.storage';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestService extends VonRestService {
  static session?: string;

  constructor(
    override http: HttpClient,
    override sanitizer: DomSanitizer,
    protected cookie: CookieService,
    protected translate: TranslateService,
    protected appStorageService: AppStorageService
  ) {
    super(http, sanitizer);
  }

  override authenticate = (
    url: string,
    username: string,
    password: string,
    setCookie: boolean = true
  ): Observable<any> => {
    const authorization = btoa(`${username}:${password}`);
    const headers = new HttpHeaders({
      authorization: `Basic ${authorization}`,
      Accept: 'text/plain',
    });
    return this.http
      .get(url, {
        headers,
        withCredentials: true,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        take(1),
        share(),
        catchError((err) => {
          return throwError(() => err.body);
        }),
        tap((res: HttpResponse<any>) => {
          this.appStorageService.set(AppStorage.SESSION, res.body);
          RestService.session = res.body;
        }),
        map((res: HttpResponse<any>) => res.body)
      );
  };

  uploadFile = <R = any>(
    url: string,
    data: FormData,
    urlParams?: any,
    queryParams?: any
  ): Observable<R> => {
    const headers = new HttpHeaders({
      Accept: 'application/json',
    });
    url = this.customSetUrlParams(url, urlParams);
    return this.http.post<R>(url, data, {
      headers,
      params: queryParams,
      withCredentials: true,
    });
  };

  setParams = (params: any) => {
    if (params == null) {
      return {};
    }
    const queryParams: any = {};
    for (const p in params) {
      if (p === 'date') {
        queryParams[p] = new LocalizedDatePipe(this.translate).transform(
          new Date(params[p]),
          'yyyy-MM-dd'
        );
      } else {
        queryParams[p] = params[p];
      }
    }
    return queryParams;
  };

  addParamsToPath = (endpoint: string, pathParams: any, urlParams?: any) => {
    const finalUrl = this.customSetUrlParams(endpoint, urlParams);
    const queryParams = this.setParams(pathParams);
    let queryParamsUrl = '?';
    for (const p in queryParams) {
      queryParamsUrl = queryParamsUrl.concat(`${p}=${queryParams[p]}&`);
    }
    return `${finalUrl}${queryParamsUrl.replace(/[&](?!.)/, '')}`;
  };

  lazyLoadingGoogleMaps = (): Observable<boolean> => {
    const isMapLoaded = sessionStorage.getItem('google_map_loaded');
    if (isMapLoaded != null && isMapLoaded === 'true') {
      return of(true);
    }
    return this.http
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}`,
        'callback'
      )
      .pipe(
        map(() => {
          sessionStorage.setItem('google_map_loaded', 'true');
          return true;
        }),
        catchError((err) => {
          return of(false);
        })
      );
  };

  private customSetUrlParams = (url: string, params?: any) => {
    if (params == null) {
      return url;
    }
    for (const p in params) {
      url = url.replace(`{${p}}`, params[p]);
    }
    return url;
  };
}
