import { Component, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { BaseFiltersComponent } from 'src/app/core/base/base-filters.component';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';
import { Gen0039 } from 'src/app/models/general-information/gen-0039.specialty';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';

@Component({
  selector: '[app-filters-campaign]',
  templateUrl: './app.filters.crm.campaign.component.html',
})
export class AppFiltersCrmCampaignComponent extends BaseFiltersComponent {
  @ViewChild('specialtyFilter') specialtyFilter?: Dropdown;

  specialtyOptions: Gen0039[] = [];
  specialtySelected?: string;

  override triggerOr = false;

  constructor(
    override store: Store<any>,
    override storage: AppStorageService,
    override renderer: Renderer2,
    override translate: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store.select('filters', 'specialties').subscribe((specialties) => {
      this.specialtyOptions = [
        { specialtyCode: 'TODOS', specialty: 'ALL' },
        ...specialties,
      ];
    });

    this.store
      .select('filters', 'specialtyOption')
      .subscribe((specialtyOption: string) => {
        this.specialtySelected = specialtyOption;

        if (this.specialtySelected === 'TODOS') {
          this['manualChangeDomForFilter'](
            this.specialtySelected,
            this.specialtyFilter
          );
        }
      });
  }

  onSpecialtyChange = ($ev: DropdownChangeEvent) => {
    const { value } = $ev;
    this.store.dispatch(
      appStoreFiltersActions.setSpecialtyOptionAction({
        specialtyCode: value,
      })
    );
  };

  override customClearFilters = () => {
    this.store.dispatch(
      appStoreFiltersActions.setSpecialtyOptionAction({
        specialtyCode: 'TODOS',
      })
    );
  };
}
