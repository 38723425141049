import { createAction, props } from '@ngrx/store';
import { CampaignModel } from 'src/app/pages/admin/campaign/model/admin.campaign';

export const appStoreAdminCampaignActions = {
  setActiveCampaignsAction: createAction(
    'ADMIN_CAMPAIGN_SET_ACTIVE_CAMPAIGNS',
    props<{ activeCampaigns: CampaignModel[] }>()
  ),

  setCampaignToPublishAction: createAction(
    'ADMIN_CAMPAIGN_SET_CAMPAIGN_TO_PUBLISH',
    props<{ id: number }>()
  ),

  removeFromActiveCampaignAction: createAction(
    'ADMIN_CAMPAIGN_REMOVE_FROM_ACTIVE_CAMPAIGN',
    props<{ id: number }>()
  ),

  setCurrentCampaignAction: createAction(
    'ADMIN_CAMPAIGN_SET_CURRENT_CAMPAIGN',
    props<{ currentCampaign: CampaignModel }>()
  ),
};
