<div class="col-12 flex flex-wrap p-0 border-round border-1 border-dashed surface-border md:border-none">
  <div class="col-12 md:col p-2 md:p-0 flex flex-column">
    <label class="font-bold" for="segmentFilter">{{ "PRODUCT" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        #segmentFilter
        name="segmentFilter"
        [(ngModel)]="segmentSelected"
        (onChange)="onSegmentChange($event)"
        [options]="segmentOptions"
        optionLabel="label"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>

  <div [ngClass]="{ 'col-12 p-0': isSmall }">
    <p-divider align="center" [layout]="isSmall ? 'horizontal' : 'vertical'" type="dashed">
      <span class="p-tag" *ngIf="isSmall" translate="FILTERS_OR"></span>
    </p-divider>
  </div>

  <div class="col-12 md:col p-2 md:p-0 flex flex-column">
    <label class="font-bold" for="specialtyFilter">{{ "SPECIALTY" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        #specialtyFilter
        name="specialtyFilter"
        [(ngModel)]="specialtySelected"
        (onChange)="onSpecialtyChange($event)"
        [options]="specialtyOptions"
        optionLabel="specialty"
        optionValue="specialtyCode"
        styleClass="w-full"
        appendTo="body"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.specialty"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.specialty"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>

  <div class="col-12 p-0" [ngClass]="{ 'pb-2': !isSmall }">
    <p-divider align="center" layout="horizontal" type="dashed" styleClass="{{ !isSmall ? 'm-0' : '' }}">
      <span class="p-tag" translate="FILTERS_OR"></span>
    </p-divider>
  </div>

  <div class="col md:col-3 p-0"></div>
  <div class="col-6 md:col p-2 md:p-0 flex flex-column">
    <label class="font-bold" for="customerAltFilter">{{ "CUSTOMER" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        #customerAltFilter
        [(ngModel)]="clientAltSelected"
        (onChange)="onClientAltChange($event)"
        name="customerAltFilter"
        [options]="clientAltOptions"
        optionLabel="label"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="col md:col-3 p-0"></div>
</div>

<div class="text-right mt-4">
  <p-button icon="pi pi-eraser text-2xl" type="button" [outlined]="true" [rounded]="true" severity="danger" [text]="true" (onClick)="clearFilters()"></p-button>
</div>
