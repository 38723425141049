import { Component, OnDestroy, OnInit } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DateUtils } from 'src/app/core/utils/date.utils';
import { CollaboratorFull } from 'src/app/models/collaborator';
import { Company } from 'src/app/models/company';
import {
  FormatType,
  FormatTypeApi,
  FormatTypeValue,
} from 'src/app/models/format-type';
import { SecurityUser } from 'src/app/models/security/security-user';
import { AppFilters } from 'src/app/store/filters/store.filters';
import { appStoreFiltersIS } from 'src/app/store/filters/store.filters.state';
import { AppGlobal } from 'src/app/store/global/store.global';
import { appStoreGlobalActions } from 'src/app/store/global/store.global.action';
import { appStoreGlobalIS } from 'src/app/store/global/store.global.state';
import { AppScreen } from 'src/app/store/screen/store.screen';
import { appStoreScreenIS } from 'src/app/store/screen/store.screen.state';
import { AppSettings } from 'src/app/store/settings/store.settings';
import { appStoreSettingsIS } from 'src/app/store/settings/store.settings.state';
import { BaseService } from './base.service';

@Component({
  template: '',
})
export abstract class BaseComponent implements OnDestroy, OnInit {
  subscriptions: { [key: string]: Subscription } = {};
  private _isLoading: boolean = false;
  private _isSaving: boolean = false;
  private _isInnerLoading: boolean = false;

  global: AppGlobal = appStoreGlobalIS;
  settings: AppSettings = appStoreSettingsIS;
  filters: AppFilters = appStoreFiltersIS;
  screen: AppScreen = appStoreScreenIS;

  user?: SecurityUser;
  collaborator?: CollaboratorFull;
  company?: Company;
  isSalesforce = true;

  agentDocument?: string;
  agentRole?: string;
  dateSelected? = new Date();

  formatTypeList: FormatType[] = [];
  formatTypeOption?: FormatType;
  formatTypeSelected: FormatTypeValue = 'fixedPoint';
  formatTypeApi: FormatTypeApi = 'units';

  isWeb = false;
  isSmall = false;
  isPortrait = false;
  isMedium = false;
  isLarge = false;

  textExp = `<span class="text-xs vertical-align-top" style="vertical-align: top;">-1</span>`;
  valueIcon = `<i class="fa-fw fa-solid fa-dollar-sign"></i>`;
  p360Icon = `<i class="p360 u-icon"></i>`;

  innerHeight = 0;
  innerWidth = 0;
  screenHeight = 0;
  screenWidth = 0;

  connected = true;

  shouldScrollToTop = false;

  currentDate?: Date;
  endCurrentDate?: Date;
  nextDate?: Date;

  showGpsError = false;
  isWatchingPosition = true;
  currentPosition: { latitude?: number; longitude?: number } = {
    latitude: 0,
    longitude: 0,
  };

  private gpsTries = 0;
  private timeoutId?: any;

  protected ignoreClaims?: boolean = true;

  constructor(
    protected service?: BaseService,
    protected platform?: Platform,
    protected store?: Store<any>
  ) {
    this.subscriptions = {};
  }

  ngOnInit() {
    if (this.shouldScrollToTop) {
      document.getElementById('layout-content').scrollTo({ top: 0 });
    }

    if (!this.currentDate) {
      this.currentDate = new Date();
      this.currentDate.setHours(0, 0, 0, 0);
    }
    this.endCurrentDate = new Date(this.currentDate);
    this.endCurrentDate.setHours(23, 59, 59, 999);
    this.nextDate = new Date(this.currentDate);
    DateUtils.addDate(this.nextDate, 1);

    this.getScreenHeightWithoutTopBar();
    this.platform?.resize.subscribe(() => {
      setTimeout(() => {
        this.getScreenHeightWithoutTopBar();
      }, 50);
      setTimeout(() => {
        this.onResize();
      }, 100);
    });

    this.subscriptions.settings = this.store
      ?.select('settings')
      .subscribe((settings: AppSettings) => {
        this.settings = settings;
      });

    this.subscriptions.filters = this.store
      ?.select('filters')
      .subscribe((filters: AppFilters) => {
        this.filters = filters;

        this.dateSelected = filters.dateSelected;

        this.formatTypeList = filters.formatTypeList!;
        this.formatTypeOption = filters.formatTypeOption;
        this.formatTypeSelected = filters.formatTypeSelected!;
        this.formatTypeApi = filters.formatTypeApi!;
      });

    this.subscriptions.global = this.store
      ?.select('global')
      .subscribe((global: AppGlobal) => {
        this.global = global;

        this.user = global.user;
        this.collaborator = global.collaborator;
        this.company = global.company;
        this.isSalesforce = global.isSalesforce;

        this.agentDocument = global.document;
        this.agentRole = global.role;

        this._isLoading = global.isLoading;
        this._isSaving = global.isSaving;

        this.isWeb = global.isWeb;
        this.isSmall = global.isSmall;
        this.isPortrait = global.isPortrait;
        this.isMedium = global.isMedium;
        this.isLarge = global.isLarge;

        this.innerHeight = global.innerHeight;
        this.innerWidth = global.innerWidth;
      });

    this.subscriptions.globalConnected = this.store
      ?.select('global', 'connected')
      .subscribe((connected: boolean) => {
        this.connected = connected;
        this.networkStatusChanged();
      });

    this.subscriptions.screen = this.store
      ?.select('screen')
      .subscribe((screen: AppScreen) => {
        this.screen = screen;
        const { claims, screenId } = screen;

        if (!this.ignoreClaims && claims && screenId) {
          const hasLoadClaim = claims[screenId]?.includes('load') ?? false;
          if (!hasLoadClaim) {
            window.location.href = '/p360';
          }
        }
      });
  }

  ngOnDestroy() {
    this.isLoading = false;
    for (const subs in this.subscriptions) {
      this.subscriptions[subs]?.unsubscribe();
    }
  }

  protected onResize = () => {};

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    setTimeout(() => {
      this.store?.dispatch(
        appStoreGlobalActions.setIsLoadingAction({
          isLoading,
        })
      );
    }, 0);
  }

  get isInnerLoading() {
    return this._isInnerLoading;
  }

  set isInnerLoading(_isInnerLoading: boolean) {
    this._isInnerLoading = _isInnerLoading;
  }

  get isSaving() {
    return this._isSaving;
  }

  set isSaving(isSaving: boolean) {
    this._isSaving = isSaving;
    this.store?.dispatch(
      appStoreGlobalActions.setIsSavingAction({
        isSaving,
      })
    );
  }

  /** @deprecated */
  handleSaving = (isSaving: boolean = false) => {
    this._isSaving = isSaving;
    this.store?.dispatch(
      appStoreGlobalActions.setIsSavingAction({
        isSaving,
      })
    );
  };

  handleRestErrorMessage = (
    error: any,
    showWarning = false,
    sticky = false
  ) => {
    this.isLoading = false;
    this.service?.handleRestErrorMessage(error, showWarning, sticky);
  };

  get unitsFormat(): string | undefined {
    if (!this.settings.format) {
      return undefined;
    }

    return this.settings.format === 'units'
      ? 'fixedPoint'
      : this.settings.format;
  }

  get valueFormat(): string | undefined {
    if (!this.settings.format) {
      return undefined;
    }

    return this.settings.format === 'units' ? 'currency' : this.settings.format;
  }

  protected networkStatusChanged = () => {};

  private getScreenHeightWithoutTopBar = () => {
    const layoutContentWrapper = document.querySelector(
      '.layout-content-wrapper'
    );
    let wrapperPaddingTop = 0;
    if (layoutContentWrapper) {
      const wrapperComputedStyles = window.getComputedStyle(
        layoutContentWrapper,
        null
      );
      wrapperPaddingTop = +(wrapperComputedStyles.paddingTop ?? '0').replace(
        'px',
        ''
      );
    }

    const layoutContent = document.querySelector('.layout-content');
    let contentPaddingBottom = 0;
    let contentPaddingTop = 0;
    if (layoutContent) {
      const contentComputedStyles = window.getComputedStyle(
        layoutContent,
        null
      );
      contentPaddingBottom = +(
        contentComputedStyles.paddingBottom ?? '0'
      ).replace('px', '');
      contentPaddingTop = +(contentComputedStyles.paddingTop ?? '0').replace(
        'px',
        ''
      );
    }

    const layoutFooter = document.querySelector('.layout-footer');
    let footerHeight = 0;
    if (layoutFooter) {
      footerHeight = layoutFooter.scrollHeight
        ? layoutFooter.scrollHeight + 10
        : 0;
    }

    this.screenHeight =
      this.platform?.height() -
      wrapperPaddingTop -
      contentPaddingBottom -
      contentPaddingTop -
      footerHeight -
      2;
    this.screenWidth = this.platform?.width();
  };

  protected watchPosition = () => {
    this.isWatchingPosition = true;
    if (this.isWeb) {
      navigator.permissions.query({ name: 'geolocation' }).then();
      navigator.geolocation.getCurrentPosition(
        (res) => {
          this.currentPosition = {
            latitude: res?.coords?.latitude ?? 0,
            longitude: res?.coords?.longitude ?? 0,
          };
          this.showGpsError = false;
          this.isWatchingPosition = false;
        },
        () => {
          this.showGpsError = true;
          setTimeout(() => {
            this.watchPosition();
          }, 2500);
        },
        {
          enableHighAccuracy: true,
          timeout: 1000,
        }
      );
    } else {
      if (this.gpsTries < 2) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: false,
          timeout: 1000,
        })
          .then((res) => {
            if (res) {
              this.currentPosition = {
                latitude: res?.coords?.latitude ?? 0,
                longitude: res?.coords?.longitude ?? 0,
              };
              this.showGpsError = false;
            } else {
              this.showGpsError = true;
              this.gpsTries++;
              this.timeoutId = setTimeout(() => {
                this.watchPosition();
              }, 2500);
            }
          })
          .catch(() => {
            this.showGpsError = true;
            this.gpsTries++;
            this.timeoutId = setTimeout(() => {
              this.watchPosition();
            }, 2500);
          });
      } else if (this.timeoutId) {
        clearInterval(this.timeoutId);
      }
    }
  };
}
