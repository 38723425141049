import { Action, createReducer, on } from '@ngrx/store';
import { AppScreen } from './store.screen';
import { appStoreScreenActions } from './store.screen.action';
import { appStoreScreenIS } from './store.screen.state';

const appStoreScreenRegister = createReducer(
  appStoreScreenIS,

  on(appStoreScreenActions.setCurrentScreen, (state, { screenId, screen }) => ({
    ...appStoreScreenIS,
    screenId,
    [screen]: true,
    claims: state.claims,
  })),

  on(appStoreScreenActions.setHideFiltersAction, (state, { hideFilters }) => ({
    ...state,
    hideFilters,
  })),

  on(
    appStoreScreenActions.setHideDateFilterAction,
    (state, { hideDateFilter }) => ({
      ...state,
      hideDateFilter,
    })
  ),

  on(appStoreScreenActions.setClaimsAction, (state, { claims }) => ({
    ...state,
    claims,
  })),

  on(appStoreScreenActions.setHideSettingsAction, (state, { hideSettings }) => ({
    ...state,
    hideSettings,
  })),
);

export const appStoreScreenReducer = (state: AppScreen, action: Action): any =>
  appStoreScreenRegister(state, action);
