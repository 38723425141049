import { DecimalPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DxChartComponent } from 'devextreme-angular';
import { SeriesPoint } from 'devextreme/common/charts';
import { LegendClickEvent } from 'devextreme/viz/chart';
import { BaseComponent } from 'src/app/core/base/base.component';
import { AppColor } from 'src/app/models/app.colors';

@Component({
  selector: 'app-chart-complete',
  templateUrl: './app-chart-complete.html',
})
export class AppChartCompleteComponent extends BaseComponent implements OnInit {
  @ViewChild('appChartComplete') appChartComplete?: DxChartComponent;

  @Input() dataSource: any[] = [];
  @Input() formatType?: string;
  @Input() height?: number;
  @Input() rotationAngle = -45;
  @Input() fontSize = '.85rem';
  @Input() argumentField = 'date';
  @Input() maxTextLength = 20;

  @Output() contentReady = new EventEmitter<any>();

  isLoaded = false;
  valueAxisLabel = true;
  appColors?: AppColor;
  percentTickInterval?: number;

  axis: any[] = [];
  showEvolutionSerie = false;
  forceRender = true;

  constructor(
    override store: Store<any>,
    protected decimalPipe: DecimalPipe,
    protected elRef: ElementRef
  ) {
    super();
  }

  override ngOnInit() {
    this.setAxisInformation();

    this.store?.select('global', 'isSmall').subscribe((isSmall) => {
      setTimeout(() => {
        this.valueAxisLabel = !isSmall;
        this.setAxisInformation();
      }, 0);
    });

    this.store?.select('global', 'appColors').subscribe((appColors) => {
      this.appColors = appColors;
      this.setAxisInformation();
    });

    super.ngOnInit();
  }

  onLegendClickEvent = ($event: LegendClickEvent) => {
    const { target: series } = $event;

    if (series.axis === 'evolution') {
      this.showEvolutionSerie = !this.showEvolutionSerie;
      this.axis[2].visible = this.showEvolutionSerie;
      this.axis[2].label.visible = this.showEvolutionSerie;
      this.forceRender = false;
      setTimeout(() => {
        this.forceRender = true;
      }, 250);
    } else if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  };

  customizeTextEvent = (e: any) => {
    if (this.maxTextLength === 0 || e.valueText.length <= this.maxTextLength) {
      return e.valueText;
    }
    return e.valueText.substring(0, this.maxTextLength).concat('...');
  };

  onContentReadyEvent = (e: any) => {
    if (!this.isLoaded) {
      const maxPercentTickValue = Math.max(
        ...this.dataSource.map((item) =>
          item.previousPart > item.currentPart
            ? item.previousPart
            : item.currentPart
        )
      );
      this.isLoaded = true;
    } else {
      this.contentReady.emit(e);
    }
  };

  reloadDatasource = (datasource: any[]) => {
    this.appChartComplete?.instance.beginUpdate();
    this.appChartComplete?.instance.option('dataSource', datasource);
    this.appChartComplete?.instance.endUpdate();
  };

  serieCustomLabel = (arg: any) => {
    if (arg.value === 0) {
      return '';
    }
    return arg.valueText;
  };

  customizePoint = ($ev: any): SeriesPoint => {
    if (!$ev.value || $ev.value === 0) {
      return {
        visible: false,
      };
    }
    return {};
  };

  get planChart() {
    return this.company.theme.planChart ?? this.appColors.charts.plan;
  }
  get planText() {
    return this.company.theme.planText ?? this.appColors.text.plan;
  }

  get valueCurrentChart() {
    return (
      this.company.theme.currentValueChartAlt ?? this.appColors.charts.sales
    );
  }
  get valueCurrentText() {
    return this.company.theme.currentValueTextAlt ?? this.appColors.text.sales;
  }

  get valuePrevChart() {
    return (
      this.company.theme.previousValueChart ?? this.appColors.charts.salesPrev
    );
  }
  get valuePrevText() {
    return (
      this.company.theme.previousValueText ?? this.appColors.text.salesPrev
    );
  }

  get fulfillmentCurrentChart() {
    return (
      this.company.theme.fulfillmentChart ?? this.appColors.charts.fulfillment
    );
  }
  get fulfillmentCurrentText() {
    return (
      this.company.theme.fulfillmentText ?? this.appColors.text.fulfillment
    );
  }

  get fulfillmentPrevChart() {
    return (
      this.company.theme.previousFulfillmentChart ??
      this.appColors.charts.fulfillmentPrev
    );
  }
  get fulfillmentPrevText() {
    return (
      this.company.theme.previousFulfillmentText ??
      this.appColors.text.fulfillmentPrev
    );
  }

  get evolutionPrevChart() {
    return (
      this.company.theme.evolutionChart ?? this.appColors.charts.projection
    );
  }
  get evolutionPrevText() {
    return this.company.theme.evolutionText ?? this.appColors.text.projection;
  }

  private readonly setAxisInformation = () => {
    this.axis = [
      {
        position: 'left',
        grid: { visible: true },
        label: {
          visible: this.valueAxisLabel,
          format: {
            type: this.formatType,
            precision: this.settings.precision,
          },
        },
      },
      {
        name: 'percent',
        position: 'right',
        visible: false,
        grid: { visible: false },
        label: {
          visible: this.valueAxisLabel,
          format: {
            type: 'percent',
            precision: 0,
          },
        },
        tick: { visible: false },
      },
      {
        name: 'evolution',
        position: 'right',
        visible: this.showEvolutionSerie,
        color: '#318C31',
        min: 0,
        grid: { visible: true },
        label: {
          visible: this.showEvolutionSerie,
          font: {
            color: '#318C31',
          },
          format: {
            type: 'fixedPoint',
            precision: 0,
          },
        },
        title: {
          text: this.valueAxisLabel ? 'IE' : '',
          font: { color: '#318C31' },
        },
        tick: { visible: false },
      },
    ];
  };
}
