/* eslint-disable @typescript-eslint/naming-convention */
interface AppRestEndpoints {
  LOGIN: string;
  LOGOUT: string;
  WHO_AM_I: string;
  HEALTHCHECK: string;

  ADMIN_RESOURCE: string;
  ADMIN_CAMPAIGN: string;

  APPOINTMENT_HISTORY: string;
  AUDIT_CUBE: string;
  COMPANY_PLANNING: string;
  MAP_PLANNING: string;
  MARKET_SHARE: string;
  ORDER_HISTORY: string;
  PRE_ORDER: string;
  SALESFORCE_TREE: string;
  STOCK_ITEM: string;
  CAMPAIGN_RESOURCE: string;

  GEN_0000: string;
  GEN_0001: string;
  GEN_0002: string;
  GEN_0003: string;
  GEN_0004: string;
  GEN_0019: string;
  GEN_0021: string;
  GEN_0022: string;
  GEN_0026: string;
  GEN_0027: string;
  GEN_0030: string;
  GEN_0033: string;
  GEN_0036: string;
  GEN_0036_ID: string;
  GEN_0037: string;
  GEN_0038: string;
  GEN_0039: string;
  GEN_0040: string;
  GEN_0041: string;
  RX_0001: string;
  RX_0002: string;
  RX_0003: string;
  RX_0024: string;
  RX_0035: string;
  MDO_0043: string;
  MDO_0044: string;
  MDO_0045: string;
  SAVE_APPOINTMENT: string;
  SAVE_APPOINTMENT_PHOTO: string;
  SAVE_MAP_PLANNING: string;
  SAVE_ORDER: string;
  SAVE_ORDER_PHOTO: string;
  SAVE_ROUTE: string;
  SAVE_STAKEHOLDER: string;
  SAVE_APPOINTMENT_OFF: string;
  SAVE_ORDER_OFF: string;
  SAVE_APPOINTMENT_STOCK: string;
  SAVE_APPOINTMENT_FACE: string;
  SAVE_APPOINTMENT_FACE_PHOTO: string;
  SAVE_APPOINTMENT_TRAINING: string;
  SAVE_APPOINTMENT_TRAINING_PHOTO: string;
  SAVE_APPOINTMENT_DISPLAY: string;
  SAVE_APPOINTMENT_DISPLAY_PHOTO: string;
  SAVE_CLIENT: string;
  DELETE_MAP_PLANNING: string;
  SELLIN_0001: string;
  SELLIN_0002: string;
  SELLIN_0006_COLAB: string;
  SELLIN_0006_APPOINTMENT: string;
  SELLIN_0008: string;
  SELLIN_0009: string;
  SELLIN_0010: string;
  SELLIN_0011: string;
  SELLIN_0012: string;
  SELLIN_0013: string;
  SELLIN_0017: string;
  SELLIN_0018: string;
  SELLIN_0020: string;
  SELLIN_0028: string;
  SELLIN_0029: string;
  SELLIN_0034: string;
}

export class AppConstants {
  static SECURITY_HOST = `https://p360security.bisigma.com/api`;
  static MOBILE_HOST = `api/v2`;

  static REST_UNKNOWN_ERROR = '(HT.U)';
  static REST_BAD_REQUEST = '(HT.BR)';
  static REST_NOT_FOUND = '(HT.NF)';

  static REST_ENDPOINTS: AppRestEndpoints = {
    LOGIN: 'api/login',
    LOGOUT: 'api/logout',
    WHO_AM_I: 'api/who-am-i',
    HEALTHCHECK: 'health-check',

    ADMIN_RESOURCE: 'admin/resource',
    ADMIN_CAMPAIGN: 'admin/campaign',

    APPOINTMENT_HISTORY: 'by-name/appointment-history/{isByAgent}',
    AUDIT_CUBE: 'by-name/audit-cube/{isPrescription}',
    COMPANY_PLANNING: 'by-name/company-planning',
    MAP_PLANNING: 'by-name/map-planning',
    MARKET_SHARE: 'by-name/market-share',
    ORDER_HISTORY: 'by-name/order-history/{isByAgent}',
    PRE_ORDER: 'by-name/pre-order',
    SALESFORCE_TREE: 'by-name/salesforce-tree',
    STOCK_ITEM: 'by-name/stock-item',
    CAMPAIGN_RESOURCE: 'by-name/campaign-resource',

    GEN_0000: 'gen/0000',
    GEN_0001: 'gen/0001',
    GEN_0002: 'gen/0002',
    GEN_0003: 'gen/0003',
    GEN_0004: 'gen/0004', // To show agent information
    GEN_0019: 'gen/0019', // To fill clients filter datasource
    GEN_0021: 'gen/0021', // To fill lines filter datasource
    GEN_0022: 'gen/0022', // To fill segments filter datasource
    GEN_0026: 'gen/0026',
    GEN_0027: 'gen/0027', // To show help information
    GEN_0030: 'gen/0030', // To show dispatchers datasource
    GEN_0033: 'gen/0033', // To show options menu
    GEN_0036: 'gen/0036', // To fill dashboard R datasource
    GEN_0036_ID: 'gen/0036/{id}', // To fill dashboard R datasource
    GEN_0037: 'gen/0037', // To show collaborator KPIs
    GEN_0038: 'gen/0038', // To show samples by appointment id
    GEN_0039: 'gen/0039', // To fill specialties filter datasource
    GEN_0040: 'gen/0040', // To show active campaigns
    GEN_0041: 'gen/0041', // To show active campaigns

    RX_0001: 'rx/0001',
    RX_0002: 'rx/0002',
    RX_0003: 'rx/0003',
    RX_0024: 'rx/0024',
    RX_0035: 'rx/0035/{isByAgent}',
    MDO_0043: 'mdo/0043',
    MDO_0044: 'mdo/0044',
    MDO_0045: 'mdo/0045',

    SAVE_APPOINTMENT: 'save/appointment',
    SAVE_MAP_PLANNING: 'save/map-planning',
    DELETE_MAP_PLANNING: 'save/map-planning/{mapId}',
    SAVE_ORDER: 'save/order',
    SAVE_ROUTE: 'save/route',
    SAVE_STAKEHOLDER: 'save/stakeholder',
    SAVE_CLIENT: 'save/client',

    SAVE_APPOINTMENT_PHOTO: 'save/appointment/{appointmentId}',
    SAVE_ORDER_PHOTO: 'save/order/{orderId}',
    SAVE_APPOINTMENT_OFF: 'save/appointmentOffline',
    SAVE_ORDER_OFF: 'save/orderOffline',
    SAVE_APPOINTMENT_STOCK: 'save/appointment/{appointmentId}/stock',
    SAVE_APPOINTMENT_FACE: 'save/appointment/{appointmentId}/face',
    SAVE_APPOINTMENT_FACE_PHOTO:
      'save/appointment/{appointmentId}/face/{itemCode}',
    SAVE_APPOINTMENT_TRAINING: 'save/appointment/{appointmentId}/training',
    SAVE_APPOINTMENT_TRAINING_PHOTO:
      'save/appointment/{appointmentId}/training/{itemCode}',
    SAVE_APPOINTMENT_DISPLAY: 'save/appointment/{appointmentId}/display',
    SAVE_APPOINTMENT_DISPLAY_PHOTO:
      'save/appointment/{appointmentId}/display/{itemCode}',

    SELLIN_0001: 'sellin/0001',
    SELLIN_0002: 'sellin/0002',
    SELLIN_0006_COLAB: 'sellin/0006/mine',
    SELLIN_0006_APPOINTMENT: 'sellin/0006/all',
    SELLIN_0008: 'sellin/0008',
    SELLIN_0009: 'sellin/0009',
    SELLIN_0010: 'sellin/0010',
    SELLIN_0011: 'sellin/0011',
    SELLIN_0012: 'sellin/0012',
    SELLIN_0013: 'sellin/0013',
    SELLIN_0017: 'sellin/0017',
    SELLIN_0018: 'sellin/0018',
    SELLIN_0020: 'sellin/0020',
    SELLIN_0028: 'sellin/0028',
    SELLIN_0029: 'sellin/0029',
    SELLIN_0034: 'sellin/0034/{isByAgent}',
  };

  static COLOR_DEFINITION = {
    PREV_BG_COLOR: '#f8f8ff',
    PREV_BG_TEXT: '#0000ff',
    CURRENT_BG_COLOR: '#f0f8ff',
    CURRENT_BG_TEXT: 'var(--text-color)',

    UNITS_BG_COLOR: '#f8f8ff',
    UNITS_BG_TEXT: '#0000ff',
    VALUE_BG_COLOR: '#f5fffa',
    VALUE_BG_TEXT: 'var(--text-color)',
  };

  // TODO
  static ANALYTICS_SCREEN: { [key: string]: string } = {
    'PAGES_TITLES.HOME': 'Página Principal',
    'PAGES_TITLES.PERFORMANCE': 'Performance Ventas Colaborador',
    'PAGES_TITLES.PRINCIPAL': 'Página Principal',
    'PAGES_TITLES.PRINCIPAL_DETAIL': 'Detalle por Producto',
    'PAGES_TITLES.PRINCIPAL_PERFORMANCE': 'Performance Ventas Colaborador',

    'PAGES_TITLES.CRM_AND_PLANNING': 'Rutero y Planning',
    'PAGES_TITLES.CRM': 'CRM & Rutero',
    'PAGES_TITLES.SCHEDULE': 'Calendarización visita',
    'PAGES_TITLES.ANALYTIC_CUBE': 'Analítica y Cubos de Información',
    'PAGES_TITLES.SELLIN_CUBE': 'Cubo Ventas',
    'PAGES_TITLES.CUBE_PRESCRIPTION': 'Cubo Prescripciones',
    'PAGES_TITLES.CUBE_MARKET_SHARE': 'Cubo Mercado de Ventas',
    'PAGES_TITLES.KPIS_PRESCRIPTION': 'Analítica Prescripciones',
    'PAGES_TITLES.KPIS_MARKET': 'Analítica Mercado de Ventas',
    'PAGES_TITLES.CONTROL_BOARD': 'Tablero Control P360º',
    'PAGES_TITLES.DRILLDOWN': 'Drilldown Perspectiva',
    'PAGES_TITLES.KPIS': 'KPIs de Gestión',
    'PAGES_TITLES.KPIS_RADAR': 'Radar de Líneas',
    'PAGES_TITLES.KPIS_COLOR_MAP': 'Mapa de Calor',
    'PAGES_TITLES.PRESCRIPTION': 'Prescripciones',
    'PAGES_TITLES.ABOUT': 'Acerca de Nosotros',
    'PAGES_TITLES.HELP': 'Ayuda',
    'PAGES_TITLES.APPOINTMENT': 'Visitas',
    'PAGES_TITLES.APPOINTMENTS_OFFLINE': 'Sincronización de Visitas',
    'PAGES_TITLES.ORDER': 'Pedidos',
    'PAGES_TITLES.ORDERS_OFFLINE': 'Sincronización de Pedidos',
    'PAGES_TITLES.DETAIL': 'Detalle',
    'PAGES_TITLES.ADD': 'Agregar',
    'PAGES_TITLES.LIST': 'Listar',

    'APPOINTMENT.SUCCESS': 'Registro de visita exitoso',
    'APPOINTMENT.FAILURE': 'Registro de visita fallido',
    'ORDER.SUCCESS': 'Registro de pedido exitoso',
    'ORDER.FAILURE': 'Registro de pedido fallido',
    FAILURE_PHOTO: 'Envío de foto fallido',
    'SELLIN_CUBE.ANALYSIS': 'Análisis ',
    'CONTROL_BOARD.LINE': 'Detalle por Línea',
    'CONTROL_BOARD.PRODUCT': 'Detalle por Producto',
    'CONTROL_BOARD.ITEM': 'Detalle por Ítem',
    'PRESCRIPTION.SEGMENT': 'Análisis por Segmento Competitivo',
    'PRESCRIPTION.PRODUCT': 'Análisis Prescriptores por Producto',
  };
}
