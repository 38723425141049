import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppFiltersComponent } from './app.filters.component';
import { AppFiltersAnalyticsCubesAuditComponent } from './components/app.filters.analytics-cubes.audit.component';
import { AppFiltersAnalyticsCubesKpiComponent } from './components/app.filters.analytics-cubes.kpi.component';
import { AppFiltersAnalyticsCubesSellinComponent } from './components/app.filters.analytics-cubes.sellin.component';
import { AppFiltersControlBoardComponent } from './components/app.filters.control-board.component';
import { AppFiltersCrmCampaignComponent } from './components/app.filters.crm.campaign.component';
import { AppFiltersDrilldownComponent } from './components/app.filters.drilldown.component';
import { AppFiltersKpisHeatmapComponent } from './components/app.filters.kpis.heatmap.component';
import { AppFiltersRxComponent } from './components/app.filters.rx.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    ButtonModule,
    DividerModule,
    DropdownModule,
    InputSwitchModule,
    RadioButtonModule,
    SelectButtonModule,
    SidebarModule,

    SharedModule,
  ],
  declarations: [
    AppFiltersComponent,
    AppFiltersAnalyticsCubesAuditComponent,
    AppFiltersAnalyticsCubesKpiComponent,
    AppFiltersAnalyticsCubesSellinComponent,
    AppFiltersControlBoardComponent,
    AppFiltersDrilldownComponent,
    AppFiltersKpisHeatmapComponent,
    AppFiltersRxComponent,
    AppFiltersCrmCampaignComponent,
  ],
  exports: [AppFiltersComponent],
})
export class AppFiltersModule {}
