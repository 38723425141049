<p-confirmDialog #reusableDialog [key]="key" appendTo="body" [styleClass]="isSmall ? 'max-w-80p' : 'max-w-65p'">
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-column align-items-center p-5 surface-overlay border-round m-auto">
      <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
        <i class="pi pi-question text-5xl"></i>
      </div>

      <span
        class="text-2xl block mb-2 mt-4 text-center"
        [innerHTML]="
          overrideQuestion ?? 'CONFIRMATION_DIALOG.TITLE'
            | translate
              : {
                  screen: headline | translate,
                  additional: subHeadline ?? '' | translate
                }
        "
      ></span>

      <div class="flex align-items-center gap-4 mt-4">
        <button pButton [label]="'NO' | translate" (click)="reusableDialog.reject()" class="p-button-outlined w-8rem"></button>
        <button pButton [label]="'YES' | translate" (click)="reusableDialog.accept()" class="w-8rem"></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
