import { Action, createReducer, on } from '@ngrx/store';
import { AppStoreAdminCampaign } from './store.admin-campaign';
import { appStoreAdminCampaignActions } from './store.admin-campaign.action';
import { appStoreAdminCampaignIS } from './store.admin-campaign.state';

const appStoreAdminCampaignRegister = createReducer(
  appStoreAdminCampaignIS,

  on(
    appStoreAdminCampaignActions.setActiveCampaignsAction,
    (state, { activeCampaigns }) => ({
      ...state,
      activeCampaigns,
    })
  ),

  on(
    appStoreAdminCampaignActions.setCampaignToPublishAction,
    (state, { id }) => {
      const activeCampaigns = state.activeCampaigns;
      activeCampaigns.forEach((item) => {
        if (item.id === id) {
          item.isPublished = true;
        }
      });
      return {
        ...state,
        activeCampaigns,
      };
    }
  ),

  on(
    appStoreAdminCampaignActions.removeFromActiveCampaignAction,
    (state, { id }) => ({
      ...state,
      activeCampaigns: state.activeCampaigns.filter((item) => item.id !== id),
    })
  ),

  on(
    appStoreAdminCampaignActions.setCurrentCampaignAction,
    (state, { currentCampaign }) => ({
      ...state,
      currentCampaign,
    })
  )
);

export const appStoreAdminCampaignReducer = (
  state: AppStoreAdminCampaign,
  action: Action
): any => appStoreAdminCampaignRegister(state, action);
