import { AppFilters } from './store.filters';
import {
  auditMeasureList,
  auditRapidFiltersList,
  collabAuditRapidFiltersList,
  dateTypeList,
  drilldownPerspectiveList,
  formatTypeList,
  heatmapMeasureList,
  regionFilterList,
  rxMeasureList,
  salesforceSellinFilterList,
  supervisorSellinFilterList,
} from './store.filters.values';

export const appStoreFiltersIS: AppFilters = {
  dateSelected: new Date(),

  formatTypeList,
  formatTypeOption: formatTypeList[1],
  formatTypeSelected: formatTypeList[1].value,
  formatTypeApi: formatTypeList[1].apiProperty,

  dateTypeList,
  dateTypeOption: dateTypeList[0],
  dateTypeSelected: dateTypeList[0].value,

  globalCatalogList: [],
  productItemList: [],
  dispatcherList: [],

  collaboratorFilterList: [],

  clientFilterList: [],

  clientForFiltersFilterList: [],

  lineFilterList: [],

  segmentFilterList: [],

  specialties: [],
  specialtyOption: 'TODOS',

  socialMediaList: [],

  regionFilterList,
  regionFilterOption: regionFilterList[0],

  allCollabortorsList: [],

  analyticsCubes: {
    salesforceSellinFilterList,
    salesforceSellinFilterOption: salesforceSellinFilterList[0],
    supervisorSellinFilterList,
    supervisorSellinFilterOption: supervisorSellinFilterList[0],
    auditRapidFiltersList,
    auditRapidFiltersOption: auditRapidFiltersList[0],
    collabAuditRapidFiltersList,
    collabAuditRapidFiltersOption: collabAuditRapidFiltersList[0],
    auditMeasureList,
    auditMeasureOption: auditMeasureList.find((item) => item.id === 4),
    kpiType: 'TODOS',
    kpiLabel: 'TODOS',
    kpiValue: 'TODOS',
  },

  drilldownPerspectiveList,
  drilldownPerspectiveOption: drilldownPerspectiveList[1],

  kpis: {
    heatmapMeasureList: heatmapMeasureList,
    heatmapMeasureOption: heatmapMeasureList[0],
    heatmapKpiType: 'TODOS',
    heatmapKpiLabel: 'TODOS',
    heatmapKpiValue: 'TODOS',
    zGraphKpiType: 'TODOS',
    zGraphKpiLabel: 'TODOS',
    zGraphKpiValue: 'TODOS',
  },

  rxMeasureList,
  rxMeasureOption: rxMeasureList[0],
  rxType: 'TODOS',
  rxLabel: 'TODOS',
  rxValue: 'TODOS',

  campaignTypes: []
};
