import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'globalCatalog',
  pure: false,
})
export class GlobalCatalogPipe implements PipeTransform {
  constructor(private readonly store: Store<any>) {}

  transform(value: number, type?: number): Observable<string> {
    return this.store.select('filters', 'globalCatalogList').pipe(
      map((items) => items.filter((item) => item.typeCode === type)),
      map((items) => {
        const found = items.find((item) => item.id === value);
        return found?.name ?? value;
      })
    );
  }
}
