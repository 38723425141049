<section class="flex flex-column align-items-center justify-content-center gap-4 m-auto">
  <span class="empty-content__triangle-wrapper">
    <div class="empty-content__triangle">
      <div class="empty-content__triangle empty-content__triangle--small"></div>
    </div>
    <i class="empty-content__triangle-icon fa-solid fa-inbox text-primary" [style.fontSize.px]="56"></i>
  </span>
  <span class="font-semibold text-xl text-center" [innerHTML]="'MESSAGE.GENERIC_EMPTY_MESSAGE' | translate"></span>
</section>
<svg style="visibility: hidden; position: absolute" width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="goo">
      <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
      <feComposite in="SourceGraphic" in2="goo" operator="atop" />
    </filter>
  </defs>
</svg>
