import { createAction, props } from '@ngrx/store';

export const appStoreScreenActions = {
  setCurrentScreen: createAction(
    'SET_CURRENT_SCREEN',
    props<{ screenId: string; screen: string }>()
  ),

  setHideFiltersAction: createAction(
    'SET_HIDE_FILTERS',
    props<{ hideFilters: boolean }>()
  ),

  setHideDateFilterAction: createAction(
    'SET_HIDE_DATE_FILTER',
    props<{ hideDateFilter: boolean }>()
  ),

  setClaimsAction: createAction(
    'SET_CLAIMS_FROM_USER',
    props<{ claims: { [key: string]: string[] } }>()
  ),

  setHideSettingsAction: createAction(
    'SET_HIDE_SETTINGS',
    props<{ hideSettings: boolean }>()
  ),
};
