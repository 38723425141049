import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { appStoreAdminCampaignReducer } from 'src/app/pages/admin/campaign/store/store.admin-campaign.reducer';
import { appStoreFiltersReducer } from './filters/store.filters.reducer';
import { appStoreGlobalReducer } from './global/store.global.reducer';
import { appStoreScreenReducer } from './screen/store.screen.reducer';
import { appStoreSettingsReducer } from './settings/store.settings.reducer';

@NgModule({
  imports: [
    StoreModule.forRoot(
      {
        settings: appStoreSettingsReducer,
        filters: appStoreFiltersReducer,
        global: appStoreGlobalReducer,
        screen: appStoreScreenReducer,

        adminCampaign: appStoreAdminCampaignReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
  ],
  exports: [],
})
export class AppStoreModule {}
