import { AppScreen } from './store.screen';

export const appStoreScreenIS: AppScreen = {
  hideFilters: false,
  hideDateFilter: false,
  hideSettings: false,

  isPrincipal: false,

  isCrmMap: false,
  isCrmScheduler: false,
  isCrmRegistry: false,
  isCrmCampaign: false,

  isAuditRx: false,
  isAuditCubes: false,
  isAuditAnalytics: false,

  isSellinPerformance: false,
  isSellinControlBoard: false,
  isSellinCube: false,
  isSellinDrilldown: false,

  isKpisDashboards: false,
  isKpisDashboardDetail: false,
  isKpisHeatmap: false,
  isKpisRadar: false,

  isAboutUs: false,
  isContactUs: false,
  isHelpCenter: false,
  isNotFound: false,

  isProductDetails: false,
  isAppointment: false,
  isAppointmentOffline: false,
  isOrder: false,
  isOrderOffline: false,
  isCustomerDetails: false,
  isCustomerCreate: false,
};
