<dx-chart
  #appChartComplete
  id="appChartComplete"
  [dataSource]="dataSource"
  resolveLabelOverlapping="stack"
  (onLegendClick)="onLegendClickEvent($event)"
  [valueAxis]="axis"
  (onDone)="onContentReadyEvent($event)"
  [rotated]="isSmall && isPortrait"
  [customizePoint]="customizePoint"
  *ngIf="forceRender"
>
  <dxo-common-axis-settings aggregatedPointsPosition="crossTicks">
    <dxo-label>
      <dxo-font [size]="fontSize"></dxo-font>
    </dxo-label>
  </dxo-common-axis-settings>

  <dxo-common-series-settings [argumentField]="argumentField" hoverMode="none" [ignoreEmptyPoints]="true" selectionMode="none" [width]="0">
    <dxo-label backgroundColor="transparent" [showForZeroValues]="false" [visible]="true">
      <dxo-font [size]="fontSize"></dxo-font>
      <dxo-format [type]="formatType" [precision]="settings.precision"></dxo-format>
    </dxo-label>
    <dxo-point hoverMode="none" selectionMode="none" [visible]="true"></dxo-point>
  </dxo-common-series-settings>

  <dxo-argument-axis aggregationInterval="month">
    <dxo-label [customizeText]="customizeTextEvent" displayMode="rotate" [rotationAngle]="rotationAngle">
      <dxo-font [size]="fontSize"></dxo-font>
      <dxo-format [type]="isSmall ? 'MMM-yy' : 'MMM-y'"></dxo-format>
    </dxo-label>
    <dxo-tick-interval [months]="1"></dxo-tick-interval>
  </dxo-argument-axis>

  <dxi-series valueField="currentMdo" type="area" [name]="'MDO_ACT' | translate" [color]="planChart">
    <dxo-point color="transparent" symbol="square">
      <dxo-border [visible]="false" color="#000000"></dxo-border>
    </dxo-point>
    <dxo-label position="top" backgroundColor="transparent" [customizeText]="serieCustomLabel">
      <dxo-font [color]="planText" size="1.1rem" [weight]="700"></dxo-font>
    </dxo-label>
  </dxi-series>

  <dxi-series valueField="previousLab" type="line" [name]="'LAB_ANT' | translate" [color]="valuePrevChart" [visible]="false" [width]="2">
    <dxo-point [visible]="false"></dxo-point>
    <dxo-label position="top" [customizeText]="serieCustomLabel">
      <dxo-font [color]="valuePrevText" size="1rem" [weight]="500"></dxo-font>
    </dxo-label>
  </dxi-series>

  <dxi-series valueField="currentLab" type="line" [name]="'LAB_ACT' | translate" [color]="valueCurrentChart" [width]="2">
    <dxo-point [visible]="false"></dxo-point>
    <dxo-label position="top" [customizeText]="serieCustomLabel">
      <dxo-font [color]="valueCurrentText" size="1rem" [weight]="500"></dxo-font>
    </dxo-label>
  </dxi-series>

  <dxi-series axis="percent" valueField="previousPart" type="scatter" [name]="'MSH_ANT' | translate" [color]="fulfillmentPrevChart" [visible]="false">
    <dxo-point symbol="cross">
      <dxo-border [visible]="false" color="#000000"></dxo-border>
    </dxo-point>
    <dxo-label [customizeText]="serieCustomLabel" alignment="left">
      <dxo-font [color]="fulfillmentPrevText" size="1rem" [weight]="500"></dxo-font>
      <dxo-format type="percent" [precision]="2"></dxo-format>
    </dxo-label>
  </dxi-series>

  <dxi-series axis="percent" valueField="currentPart" type="line" [name]="'MSH_ACT' | translate" [color]="fulfillmentCurrentChart">
    <dxo-point symbol="polygon" [color]="fulfillmentCurrentChart">
      <dxo-border [visible]="false"></dxo-border>
    </dxo-point>
    <dxo-label [customizeText]="serieCustomLabel" alignment="left">
      <dxo-font [color]="fulfillmentPrevText" size="1rem" [weight]="500"></dxo-font>
      <dxo-format type="percent" [precision]="2"></dxo-format>
    </dxo-label>
  </dxi-series>

  <dxi-series axis="evolution" valueField="evolution" type="line" [name]="'IE' | translate" color="#318C31" [visible]="showEvolutionSerie" dashStyle="dash" [selectionStyle]="{dashStyle: 'dash'}">
    <dxo-point symbol="triangleUp">
      <dxo-border [visible]="false" color="#000000"></dxo-border>
    </dxo-point>
    <dxo-label position="top" [customizeText]="serieCustomLabel">
      <dxo-font color="#318C31" size="1rem" [weight]="500"></dxo-font>
      <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
    </dxo-label>
  </dxi-series>

  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" hoverMode="none">
    <dxo-font [size]="fontSize"></dxo-font>
  </dxo-legend>
  <dxo-loading-indicator [show]="true"></dxo-loading-indicator>
  <dxo-size [height]="height"></dxo-size>
  <dxo-tooltip [enabled]="false"></dxo-tooltip>
</dx-chart>
