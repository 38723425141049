<button
  class="layout-config-button layout-config-button--2 p-link"
  type="button"
  (click)="onFilterButtonClick()"
  [ngClass]="{ hide: menuVisible || hideFilters, 'layout-config-button--landscape': isSmall && !isPortrait }"
>
  <i class="fa-solid fa-filter"></i>
</button>

<p-sidebar [(visible)]="visible" position="top" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-config-sidebar h-auto max-h-90p filters__sidebar" appendTo="body">
  <ng-template pTemplate="header">
    <h2 class="mb-0 text-primary">
      {{ "FILTERS_TITLE" | translate }}
    </h2>
  </ng-template>

  <section class="flex flex-column align-items-center justify-content-center mx-auto" [style.maxWidth.px]="1200">
    <div [style.maxWidth.px]="300" *ngIf="!hideDateFilter">
      <app-date-filter [dateFilter]="dateSelected" (dateChanged)="handleDateChanged($event)" dateFormat="M/y"></app-date-filter>
    </div>

    <div class="w-full" *ngIf="screen.isAuditCubes" app-filters-analytics-cubes-audit (onClear)="handleClearFilters()"></div>

    <div class="w-full" *ngIf="screen.isAuditAnalytics" app-filters-analytics-cubes-kpi (onClear)="handleClearFilters()"></div>

    <div class="w-full" *ngIf="screen.isSellinCube" app-filters-analytics-cubes-sellin (onClear)="handleClearFilters()"></div>

    <div class="w-full" *ngIf="screen.isSellinControlBoard" app-filters-control-board (onClear)="handleClearFilters()"></div>

    <div class="w-full" *ngIf="screen.isSellinDrilldown" app-filters-drilldown (onClear)="handleClearFilters()"></div>

    <div class="w-full" *ngIf="screen.isKpisHeatmap" app-filters-kpis-heatmap (onClear)="handleClearFilters()"></div>

    <div class="w-full" *ngIf="screen.isAuditRx" app-filters-rx (onClear)="handleClearFilters()"></div>

    <div class="w-full" *ngIf="screen.isCrmCampaign" app-filters-campaign (onClear)="handleClearFilters()"></div>
  </section>
</p-sidebar>
