import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
  pure: false,
})
export class SortByPipe implements PipeTransform {
  static sortByComparator(a: any, b: any): number {
    if (
      isNaN(parseFloat(a)) ||
      !isFinite(a) ||
      isNaN(parseFloat(b)) ||
      !isFinite(b)
    ) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
    } else {
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }
    return 0;
  }

  transform(input: any, config: string[] = ['+']): any {
    if (!Array.isArray(input)) {
      return input;
    }
    if (
      !Array.isArray(config) ||
      (Array.isArray(config) && config.length === 1)
    ) {
      const propertyToCheck = !Array.isArray(config) ? config : config[0];
      const desc = propertyToCheck.substr(0, 1) === '-';
      if (
        !propertyToCheck ||
        propertyToCheck === '-' ||
        propertyToCheck === '+'
      ) {
        return !desc ? input.sort() : input.sort().reverse();
      } else {
        const property =
          propertyToCheck.substr(0, 1) === '+' ||
          propertyToCheck.substr(0, 1) === '-'
            ? propertyToCheck.substr(1)
            : propertyToCheck;
        return input.sort((a: any, b: any) =>
          !desc
            ? SortByPipe.sortByComparator(a[property], b[property])
            : -SortByPipe.sortByComparator(a[property], b[property])
        );
      }
    } else {
      return input.sort((a: any, b: any) => {
        for (const prop of config) {
          const desc = prop.substr(0, 1) === '-';
          const property =
            prop.substr(0, 1) === '+' || prop.substr(0, 1) === '-'
              ? prop.substr(1)
              : prop;
          const comparison = !desc
            ? SortByPipe.sortByComparator(a[property], b[property])
            : -SortByPipe.sortByComparator(a[property], b[property]);
          if (comparison !== 0) {
            return comparison;
          }
        }
        return 0;
      });
    }
  }
}
