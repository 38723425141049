<dx-chart
  #pharmaChart
  id="pharmaChart"
  [dataSource]="dataSource"
  resolveLabelOverlapping="stack"
  (onLegendClick)="onLegendClickEvent($event)"
  (onArgumentAxisClick)="onArgumentAxisClickEvent($event)"
  [valueAxis]="axis"
  (onDone)="onContentReadyEvent($event)"
  (onInitialized)="onInitializedEvent($event)"
  [rotated]="isSmall && isPortrait"
  [customizePoint]="customizePoint"
>
  <dxo-common-axis-settings aggregatedPointsPosition="crossTicks">
    <dxo-label>
      <dxo-font [size]="fontSize"></dxo-font>
    </dxo-label>
  </dxo-common-axis-settings>

  <dxo-common-series-settings argumentField="name" hoverMode="none" [ignoreEmptyPoints]="true" selectionMode="none">
    <dxo-label backgroundColor="transparent" [showForZeroValues]="false" [visible]="true">
      <dxo-font [size]="fontSize"></dxo-font>
      <dxo-format [type]="dxoFormatType" [precision]="settings.precision"></dxo-format>
    </dxo-label>
    <dxo-point hoverMode="none" selectionMode="none" [visible]="true"></dxo-point>
  </dxo-common-series-settings>

  <dxo-argument-axis [aggregationInterval]="aggregationInterval">
    <dxo-label [customizeText]="customizeTextEvent" displayMode="rotate" [rotationAngle]="rotationAngle">
      <dxo-font [size]="fontSize"></dxo-font>
      <dxo-format [type]="typeFormat"></dxo-format>
    </dxo-label>
    <dxo-tick-interval *ngIf="isMonth" [months]="1"></dxo-tick-interval>
  </dxo-argument-axis>

  <dxi-series valueField="plan" type="area" [name]="'PLAN_WITH_LABEL' | translate: { type: symbol == null ? filters.formatTypeOption?.textSymbol : symbol }" [color]="planChart" *ngIf="showPlan">
    <dxo-point [visible]="false"></dxo-point>
    <dxo-label backgroundColor="transparent" [customizeText]="serieCustomLabel">
      <dxo-font [color]="planText"></dxo-font>
    </dxo-label>
  </dxi-series>

  <dxi-series valueField="sales" type="bar" [name]="'SALES_WITH_LABEL' | translate: { type: symbol == null ? filters.formatTypeOption?.textSymbol : symbol }" [color]="valueCurrentChart" *ngIf="showSales">
    <dxo-point [visible]="false"></dxo-point>
    <dxo-label position="inside" backgroundColor="transparent" [customizeText]="serieCustomLabel">
      <dxo-font [color]="valueCurrentText"></dxo-font>
    </dxo-label>
  </dxi-series>

  <dxi-series
    valueField="salesPrev"
    type="line"
    [name]="'SALES_WITH_LABEL_PREV' | translate: { type: symbol == null ? filters.formatTypeOption?.textSymbol : symbol, textExp: 'PREVIOUS' | translate }"
    [color]="valuePrevChart"
    [visible]="visibleSalesPrev"
    *ngIf="showSalesPrev"
  >
    <dxo-point [visible]="false"></dxo-point>
    <dxo-label position="inside" backgroundColor="transparent" [customizeText]="serieCustomLabel">
      <dxo-font [color]="valuePrevText"></dxo-font>
    </dxo-label>
  </dxi-series>

  <dxi-series
    axis="fulfillment"
    valueField="fulfillment"
    type="scatter"
    [name]="'FULFILLMENT_WITH_LABEL' | translate: { type: symbol == null ? filters.formatTypeOption?.textSymbol : symbol }"
    [color]="fulfillmentChart"
    *ngIf="showFulfillment"
  >
    <dxo-point symbol="triangleUp"></dxo-point>
    <dxo-label backgroundColor="transparent" [customizeText]="serieCustomLabel">
      <dxo-format type="percent" precision="2"></dxo-format>
      <dxo-font [color]="fulfillmentText"></dxo-font>
    </dxo-label>
    <dxo-border [visible]="false"></dxo-border>
  </dxi-series>

  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" hoverMode="none">
    <dxo-font [size]="fontSize"></dxo-font>
  </dxo-legend>
  <dxo-loading-indicator [show]="true"></dxo-loading-indicator>
  <dxo-size [height]="chartHeight"></dxo-size>
  <dxo-tooltip [enabled]="false"></dxo-tooltip>
</dx-chart>
